
section {
    height: 100%;
    width: 100vw;
}

.hero-wrapper {
    margin: 0;
}

.hero-content {
    height: 100vh;
    background-color: transparent;
    background-image: url('../assets/footer.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    object-fit: cover;
    object-position: 100% 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    h1 {
        font-size: 4em;
        text-align: center;
    }

    p {
        text-align: center;
    }

    input {
        padding: 10px 5px;
        border-bottom-color: transparent;
        border-top-color: transparent;
        border-left-color: transparent;
        border-right-color: transparent;
        border-radius: 10px;
        background-color: rgb(255,255,255, 0.84);
        @media only screen and (max-width: 375px) {
            width: 50%;
        }
    }

    input::placeholder {
        color: #777;
    }
}

.coming-soon-text {
    font-size: 6em;
    font-weight: bold;
    color: #fff;
    @media only screen and (max-width: 375px) {
        font-size: 2em;
    }
    @media only screen and (max-width: 768px) {
        font-size: 4em;
    }
}
.connecting-text {
    font-size: 1.1em;
}
.notify-me-text {
    font-size: 12px;
    color: #fff;
    font-style: italic;
    padding-top: 15px;
}

// About styles

.about-container {
    width: 100%;
}

.about-content {
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background-color: #171717;
    background-image: url('https://images.unsplash.com/photo-1618022325802-7e5e732d97a1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=748&q=80');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    object-fit: stretch;
    width: 100%;
    @media screen and (max-width: 390px) {
        flex-direction: column;
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}

.about-text-wrapper {
    padding: 50px;
    background-color: #fff;
    border-radius: 15px;
    width: 40%;
    box-shadow: 10px 15px 82px 0 rgba(255,255,255,0.8);
    transition: 0.5s;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 375px) and (max-width: 414px) {
        width: 90%;
        height: auto;
    }
    @media screen and (max-width: 768px) {
        width: 90%;
        overflow: scroll;
    }
}

.about-text {
    line-height: 21.4px;

}

.our-service-btn-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.our-services-btn-left {
    color: #fff;
    background-color: #171717;
    padding: 20px;
    border-radius: 15px;
    text-decoration: none;
    @media screen and (min-width: 390px) {
        font-size: 1em;
        padding: 10px;
    }

}
.our-services-btn-right {
    color: #171717;
    background-color: #fff;
    padding: 20px;
    border-color: #171717;
    border-radius: 15px;
    text-decoration: none;
    margin-left: 5px;
    @media screen and (min-width: 375px) and (max-width: 390px) {
        font-size: 1em;
        padding: 10px;
    }

}
.our-services-btn-left:hover {
    color: #171717;
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
    text-decoration: none;

}
.our-services-btn-right:hover {
    color: #fff;
    background-color: #171717;
    padding: 20px;
    border-radius: 15px;
    text-decoration: none;
}

.scroll-content {
    padding-top: 55px;
    z-index: 999;

}

.scroll-text {
    background-color: #fff;
    width: 250px;
    height: 250px;
    border-radius: 50%;

}

// Press styles

.press-content {
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(5);
    background-color: #393a3b;
    background-image: url('https://images.unsplash.com/photo-1624359209426-f68776cfb632?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=536&q=80');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    object-fit: stretch;

}
.pressNmedia {
    grid-column: 1/12;
    grid-row: 1/2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: 5px;
    @media only screen and (max-width: 375px) {
        grid-column: 1/12;
        grid-row: 1;
        display: flex;
        justify-content: flex-end;
        padding-left: 15px;
        align-items: center;

        p {
            font-size: 1.9em;
            color: #fff;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 1.5px;
        }
    }
    @media only screen and (max-width: 390px) {
        grid-column: 1/12;
        grid-row: 1;
        display: flex;
        justify-content: flex-end;
        padding-left: 15px;
        align-items: center;

        p {
            font-size: 1.9em;
            color: #fff;
            font-weight: bold;
            text-transform: uppercase;
            @media only screen and (max-width: 375px) {
                font-size: 2em;
            }
        }
    }
    @media only screen and (max-width: 768px) {}

    p {
        font-size: 3em;
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;
        @media only screen and (max-width: 375px) {
            font-size: 2em;
        }
    }
}
.press-media-text {
    font-family: 1.5em;
    text-shadow: 10px 5px #171717;
}

.press-company-info-container {
    grid-column: 6/12;
    grid-row: 3/4;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    @media only screen and (max-width: 390px) {
        grid-column: 1/12;
        grid-row: 1/3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }
    @media only screen and (max-width: 768px) {}
}

.press-company-info {
    color: #fff;
    font-size: 2em;
    text-transform: uppercase;
    font-weight: 200;
    cursor: pointer;
    padding-left: 12px;

    a {
        text-decoration: none;
        color: #fff;
    }
    a:hover {
        color: #171717;
    }
    @media only screen and (max-width: 375px) {
        color: #fff;
        font-size: 1.5em;
        text-transform: uppercase;
        font-weight: 200;
    }
    @media only screen and (max-width: 768px) {}
}
.press-company-info:hover {
    color: #d6d6d7;
    font-size: 2em;
    text-transform: uppercase;
    font-weight: 900;
    cursor: pointer;
    text-decoration: line-through;
}

.press-release-container {
    grid-column: 1/6;
    grid-row: 2/5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 390px) {
        grid-column: 1/12;
        grid-row: 3;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

    }
    @media only screen and (max-width: 768px) {}
}
.press-release {
    color: #fff;
    font-size: 2em;
    font-weight: 200;
    text-transform: uppercase;
    padding-left: 12px;
    @media only screen and (max-width: 375px) {
        color: #fff;
        font-size: 1.5em;
        font-weight: 200;
    }
    @media only screen and (max-width: 768px) {}

}
.press-release:hover {
    color: #d6d6d7;
    font-size: 2em;
    font-weight: 900;
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: line-through;
}

.press-company-brand-assets-container {
    grid-column: 1/6;
    grid-row: 1/3;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 390px) {
        grid-column: 1/12;
        grid-row: 2;
        padding-left: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @media only screen and (max-width: 768px) {}
}

.press-brand-assets {
    color: #fff;
    font-size: 2em;
    text-transform: uppercase;
    font-weight: 200;
    display: flex;
    justify-content: flex-start;
    padding-left: 12px;

    a {
        text-decoration: none;
        color: #fff;
    }
    a:hover {
        color: #d6d6d7;
    }
    @media only screen and (max-width: 375px) {
        color: #fff;
        font-size: 1.5em;
        text-transform: uppercase;
        font-weight: 200;
    }
    @media only screen and (max-width: 768px) {}
}
.press-brand-assets:hover {
    color: #d6d6d7;
    font-size: 2em;
    text-transform: uppercase;
    font-weight: 900;
    cursor: pointer;
    text-decoration: line-through;
}

.press-down-button {
    grid-column: 1/12;
    grid-row: 4;
    display: flex;
    justify-content: flex-end;
    @media only screen and (max-width: 375px) {
        grid-column: 1/12;
        grid-row: 4;
        display: flex;
        justify-content: flex-end;
        align-items: center;

    }
    @media only screen and (max-width: 768px) {}
}

.press-what-we-offer {
    color: #fff;
    text-decoration: none;
    font-weight: bolder;
}
.press-what-we-offer:hover {
    color: #fff;
}

// Services styles

.services-content {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #171717;
    background-image: url('https://images.unsplash.com/photo-1618022325802-7e5e732d97a1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=748&q=80');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    object-fit: stretch;

}

.services-our-services-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 375) and (max-width: 390px) {
        padding-top: 1em !important;
        padding-left: 12px !important;
    }

    p {
        font-size: 2.5em;
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;
        @media only screen and (max-width: 390px) {
            font-size: 2em;
        }
    }
}

.coming-soon {
    font-size: 1.5em;
}