.main-navigation {
    background-color: transparent !important;
    top: 0;
    width: 100%;
    // z-index: 999;
    height: 100px !important;
}


.main-navigation a:active,
.main-navigation a:hover {
    background: #fff !important;
    color: #171717 !important;
    text-decoration: line-through
}

.main-navigation a {
    text-decoration: none;
    color: #fff !important;
    border-radius: 5px;
}


.container, .container-sm {
    max-width: 90% !important;
}