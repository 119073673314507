.footer-container {
    height: 20vh;
    max-height: 100%;
    width: 100%;
    background-image: url('../assets/footer.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: flex-center;
    cursor: pointer;

    @media screen and (max-width: 390px){
        height: 35vh;
    }
}

.footer-wrapper {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(autofill, auto);
    background-color: rgb(255,255,255, 0.15);
    height: 100%;
    width: 100%;
    padding: 25px;
}

.footer-one {
    grid-column: 1/5;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.footer-social-icons {
    display: inherit;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 15px;
}

.footer-one-text {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.5em;
    font-weight: 500;
padding-top: 1em;
    @media only screen and (max-width: 390px) {
    font-size: 1.3em;
    }
    @media only screen and (max-width: 768px) {
        
    }
}
.footer-two {
    grid-column: 5/9;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 390px){
        flex-direction: column;
        justify-content:center
    }
}

.footer-two-text {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.5em;
    @media only screen and (max-width: 375px) {
        font-size: 1.3em;
    }
}
.footer-two-text:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-decoration:line-through;
    font-size: 1.5em;
}
.footer-three {
    grid-column: 10/13;
    grid-row: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer-three-text {
    color: #fff;
}

.footer-four{
    grid-column:1/13;
    grid-row: 2;
    display: flex;
    justify-content: center;
    align-items: center;

    a{
        text-decoration: none;
        color: #fff;
        padding-left: 5px;
    }
    a:hover{
        text-decoration: line-through;
        color: #fff;
        padding-left: 5px;
    }
}

.footer-four-text{
    font-size: 11px;
    color: #fff
}

.social-icon{
    color: #d6d6d7;
}
.social-icon:hover{
    color: #171717;
}